import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = () => {
 
        function _login(e) {
            e.preventDefault();
            let username = e.target.username.value;
            let password = e.target.password.value;
            axios.post('/login', { username: username, password: password }).then((res) => {
                if (res.data === "err") {
                    toast.error("ناو یان وشەی نهێنی هەڵەیە", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                } else {
                   window.location.href = '/items';
                }
            }) }

    return (
        <div class="canvas">
            <div class="overlay-black"></div>
            <div class="page-cover">
                <div class="container-fluid">
                    <h3><span class="cover-left-icon float-left"><i class="fa fa-lock"></i></span>Login<span
                        class="cover-right-icon float-right"><i class="fa fa-lock"></i></span></h3>
                </div>
            </div>

            <section class="page-wrapper innerpage-section-padding">
                <div id="login-page">
                    <div class="container-fluid text-center">
                        <div class="innerpage-heading">
                            <h3>QRCODE MENU</h3>
                            <p>Login The System to Update Your Menu !
                            </p>
                        </div>
                        <form class="custom-form" onSubmit={_login}>
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="text" class="form-control" name='username' placeholder="User Name or Email" required />
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="fa fa-user"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="password"  name='password' class="form-control" placeholder="Password" required />
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="fa fa-lock"></i></span>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-orange btn-radius">Login</button>
                        </form> 
                        
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Login;