import { createBrowserRouter } from "react-router-dom";
import Login from "./Admin/login";
import App from "./App";
import Category from "./Admin/category";
import Items from "./Admin/items";
import Message from "./Admin/message";
 

const router = createBrowserRouter([
    {
        path: "/login", element:
            <Login/>,
    },
    {
        path: "/", element:
            <App/>
    },
    {
        path: "/category", element:
            <Category/>
    },
    {
        path: "/items", element:
            <Items/>
    },
    {
        path: "/message", element:
            <Message/>
    },
     
]);

export default router;