import $ from 'jquery';
const HeaderAdmin = () => {
    var checking = false;
    const OpenSlider = () => {

        $('#mySidenav').toggle();
        if (!checking) {
            $('.overlay-black').css({ 'visibility': 'visible' })
            checking = true;
        }
        else {
            $('.overlay-black').css({ 'visibility': 'hidden' })
            checking = false;
        }
    }
    return (
        <div class="header">
            <div class="overlay-black" onClick={OpenSlider}></div>
            <div class="fixed-header">
                <div class="container-fluid">
                    <div class="header-logo">
                        <a href="#">
                            <h4><span><i class="far fa-star"></i>Admin </span>Dashboard</h4>
                        </a>
                    </div>
                    <div class="header-links">
                        <ul class="list-unstyled list-inline">
                            <li class="list-inline-item"><a href="javascript:void(0)" id="sidenav-open" onClick={OpenSlider}><span><i
                                class="fa fa-bars"></i></span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default HeaderAdmin