import { useEffect, useState } from "react";
import axios from "axios";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Home = ({ lang, data }) => {
    const [cat, setCat] = useState([]);
    const [Msg, setMsg] = useState([]);
    const [item, setItem] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8
    const cid = localStorage.getItem('cid');

    const resetPage = () => {
        setCurrentPage(1);
    };

    useEffect(() => {
        function handleScroll() {
            const isScrollAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100;
            if (isScrollAtBottom) {
                setCurrentPage((prevPage) => prevPage + 1);
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getItem = async (id) => {
        if (id) {
            const { data } = await axios.get(`/item/${id ? id : 'all'}?page=${currentPage}&limit=${itemsPerPage}`)
            setItem(data);
        } else {
            const { data } = await axios.get(`/item/${cid ? cid : 'all'}?page=${currentPage}&limit=${itemsPerPage}`)
            setItem((prevItems) => [...prevItems, ...data]);
        }
    }

    useEffect(() => {
        getItem();
    }, [currentPage, cid])

    useEffect(() => {
        axios.get("/categoryapi").then((res) => {
            setCat(res.data);
        });
        axios.get("/messageapi").then((res) => {
            setMsg(res.data[0]);
        });

    }, []);

    return (
        <>
            <div className="page-cover">
                <div className="container-fluid">
                    <h3>
                        <span className="cover-left-icon float-left"><i className="fas fa-utensils" /></span>
                        {data[`name_${lang}`]}
                        <span className="cover-right-icon float-right"><i className="fas fa-utensils" /></span>
                    </h3>
                </div>
            </div>
            <section className="page-wrapper innerpage-section-padding">
                <div id="menu-page" className="menu-grid">
                    <div className="container-fluid text-center menu">
                        <div className="innerpage-heading">
                            <h3>
                                {lang === "en" ? Msg.message_eng :
                                    lang === "ar" ? Msg.message_ar :
                                        lang === "krd" ? Msg.message_krd
                                            : Msg.message_krd
                                }
                            </h3>
                        </div>
                        <ul className="nav nav-tabs justify-content-center">
                            {cat && cat.map((item, index) => (
                                <li className="nav-item" key={index}>
                                    <a className="nav-link" href={`#${item[`name_${lang}`]}`} data-toggle="tab" onClick={() => { localStorage.setItem('cid', item.id); resetPage(); getItem(item.id) }} >
                                        {item[`name_${lang}`]}
                                    </a>
                                </li >
                            ))}
                        </ul>
                        <div className="tab-content">
                            {cat && cat.map((cat, i) => (
                                <div id={cat[`name_${lang}`]} className={i === 0 ? "tab-pane active" : "tab-pane"} key={i}>
                                    <ul className="list-unstyled">
                                        {item && item.map((item, index) => (
                                            <>
                                                <li key={index}>
                                                    <div className="dish-list"> <LazyLoadImage
                                                        src={`../../${item.cover}`}
                                                        alt={`${item[`title_${lang}`]}`}
                                                        effect="blur"
                                                        width={150}
                                                        height={100}
                                                        className="custom-image-class"
                                                        threshold={100}
                                                        data-toggle="modal"
                                                        data-target={`#view${item.id}`}
                                                        style={{
                                                            opacity: item.showing !== 0 ? 1 : 0.5
                                                        }}
                                                    />
                                                        <div className="dish-list-text">
                                                            <h4 style={{
                                                                textDecoration: item.showing !== 0 ? "" : "line-through"
                                                            }}>{`${item[`title_${lang}`]}`}</h4>
                                                            <h5>{item.price} {item.currency}</h5>
                                                            <p>{`${item[`description_${lang}`]}`}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <div className="dialog-box-wrapper form-dialog half-width">
                                                    <div className="modal fade text-center" id={`view${item.id}`} tabIndex={-1} role="dialog" aria-labelledby={`view${item.id}`} aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header ">
                                                                    <h4>{`${item[`title_${lang}`]}`}</h4>
                                                                    <button type="button" style={{ fontWeight: 'Bold' }} className="btn text-danger" data-dismiss="modal">X</button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <img src={`../../${item.cover}`} className="img-fluid" alt="dish-menu" />
                                                                    <div className="dish-list-text">
                                                                        <h4>{`${item[`title_${lang}`]}`}</h4>
                                                                        <h5>{item.price} {item.currency}</h5>
                                                                        <p>{`${item[`description_${lang}`]}`}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;