const FooterAdmin = () => {
    return (
        <section id="footer" class="section-padding">
            <div class="container-fluid text-center">
                <h3><span><i class="far fa-star"></i>Admin </span>Dashboard</h3>
                <ul class="footer-contact list-unstyled">  
                    <li><span><i class="fa fa-phone"></i></span>0750 4464791</li>
                </ul>
            </div>
        </section>
    );
}
export default FooterAdmin