import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Home from "./Pages/Home";
import Login from './Admin/login';

function App() {
  const [lang, setLang] = useState(localStorage.getItem('language') || 'krd');
  const switchLang = (event, lang) => {
    event.preventDefault();
    setLang(lang);
    localStorage.setItem('language', lang);
  };

  const [data, setData] = useState({});
  useEffect(() => {
    axios.get('/general').then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <div className="canvas">
      <div className="overlay-black" />
      <Header lang={lang} switchLang={switchLang} data={data} />
      <Home lang={lang} data={data} />
      <Footer lang={lang} data={data} /> 
    </div>
  );
}

export default App;
