import React, { useEffect, useState } from "react";
import FooterAdmin from "./footer";
import HeaderAdmin from "./header";
import SlideNav from "./slidenav";
import $ from 'jquery'
import axios from "axios";
import { toast } from "react-toastify";


const Message = () => {

    const [categoryContent, setCategoryContent] = useState([]);
    const getCategoryContent = async (id) => {
        const { data } = await axios.get(`/messageapi`);
        setCategoryContent(data);
    };

    const upload = () => {
        $('<input type="file"  />').click().on('change', async (e) => {
            for (let i = 0; i < e.target.files.length; i++) {
                var formData = new FormData();
                formData.append('file', e.target.files[i]);
                await axios.post('/upload', formData).then(res => {
                    if (res.data !== "err") {
                        $("#attachment").val(res.data);
                        $(`#upattachment`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg" alt=${res.data} />`)
                    }
                    $('.viewImg').click(function () {
                        $(this).remove()
                        axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                            if (res.data !== "err") {
                                $("#attachment").val('');
                            } else {
                                toast.error('Failed');
                            }
                        })
                    })
                })
            }
        })
    }

    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (res.data == "err") {
                window.location.href = '/login';
            }
            else {
                getCategoryContent();
            }
        });
    }, []);
    return (
        <>
            <SlideNav />
            <div class="canvas">

                <HeaderAdmin />
                <section class="page-wrapper innerpage-section-padding">
                    <div class="container-fluid">
                        <div class="tables-page">
                            <div class="heading-1">
                               
                            </div>
                            <div class="table-wrapper">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Message Krd</th>
                                            <th scope="col">Message Ar</th>
                                            <th scope="col">Message Eng</th> 
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categoryContent && categoryContent.map((item) => (
                                            item.id ? (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.message_krd}</td>
                                                    <td>{item.message_ar}</td>
                                                    <td>{item.message_eng}</td>  
                                                    <td>
                                                        <span type="button" className="badge bg-warning mx-2" data-toggle="modal" data-target={`#update${item.id}`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                        </span>
                                                        <div className="dialog-box-wrapper form-dialog half-width">
                                                            <div className="modal fade text-left" id={`update${item.id}`} tabIndex={-1} role="dialog" aria-labelledby={`update${item.id}`} aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header ">
                                                                            <h4>Edit</h4>
                                                                            <button type="button" className="btn text-danger" data-dismiss="modal">X</button>
                                                                        </div>
                                                                        <form className="form-sample" onSubmit={(e) => {
                                                                            e.preventDefault();
                                                                            const formData = new FormData(e.target);
                                                                            const data = Object.fromEntries(formData);
                                                                            axios.put(`/messageapi/${item.id}`, data).then(res => {
                                                                                if (res.data !== 'err') {
                                                                                    window.$(`#update${item.id}`).modal('hide');
                                                                                    getCategoryContent();
                                                                                }
                                                                            })
                                                                        }}>
                                                                            <div className="modal-body">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="name_krd">Message Krd</label>
                                                                                    <input id="name_krd" className="form-control" type="text" name="message_krd" defaultValue={item.message_krd} required placeholder="" />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="name_ar">Message Ar</label>
                                                                                    <input id="name_ar" className="form-control" type="text" name="message_ar" defaultValue={item.message_ar} required placeholder="" />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="name_en">Message  Eng</label>
                                                                                    <input id="name_en" className="form-control" type="text" name="message_eng" defaultValue={item.message_eng} required placeholder="" />
                                                                                </div> 
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                                                                <button type="submit" className="btn btn-success">Save</button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr >
                                            ) : null
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </section>
                <FooterAdmin />
            </div>
        </>
    );
}
export default Message