import React, { useEffect, useState } from "react";
import FooterAdmin from "./footer";
import HeaderAdmin from "./header";
import SlideNav from "./slidenav";
import $ from 'jquery'
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";


const Items = () => {

    const [categoryContent, setCategoryContent] = useState([]);
    const getCategoryContent = async () => {
        const { data } = await axios.get(`/categoryapi`);
        setCategoryContent(data);
    };

    const [itemsContent, setitemsContent] = useState([]);
    const getitemsContent = async () => {
        const { data } = await axios.get(`/itemsapi`);
        setitemsContent(data);
    };
    const upload = () => {
        $('<input type="file"  />').click().on('change', async (e) => {
            for (let i = 0; i < e.target.files.length; i++) {
                var formData = new FormData();
                formData.append('file', e.target.files[i]);
                await axios.post('/upload', formData).then(res => {
                    if (res.data !== "err") {
                        $("#attachment").val(res.data);
                        $(`#upattachment`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg" alt=${res.data} />`)
                    }
                    $('.viewImg').click(function () {
                        $(this).remove()
                        axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                            if (res.data !== "err") {
                                $("#attachment").val('');
                            } else {
                                toast.error('Failed');
                            }
                        })
                    })
                })
            }
        })
    }
    const Upupload = (id) => {
        $('<input type="file"  />').click().on('change', async (e) => {
            for (let i = 0; i < e.target.files.length; i++) {
                var formData = new FormData();
                formData.append('file', e.target.files[i]);
                await axios.post('/upload', formData).then(res => {
                    if (res.data !== "err") {
                        $(`#uploadattachment${id}`).val(res.data);
                        $(`#upattachment${id}`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg" alt=${res.data} />`)
                    }
                    $('.viewImg').click(function () {
                        $(this).remove()
                        axios.delete(`/upload/${$(this).attr('alt')}`).then(res => {
                            if (res.data !== "err") {
                                $("#uploadattachment").val('');
                            } else {
                                toast.error('Failed');
                            }
                        })
                    })
                })
            }
        })
    }

    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (res.data == "err") {
                window.location.href = '/login';
            }
            else {
                getCategoryContent();
                getitemsContent();
            }
        });
    }, []);
    return (
        <>
            <SlideNav />
            <div class="canvas">

                <HeaderAdmin />
                <section class="page-wrapper innerpage-section-padding">
                    <div class="container-fluid">
                        <div class="tables-page">
                            <div class="heading-1">
                                {/* Button trigger modal */}
                                <button type="button" className="btn btn-secondary   btn-primary buttons-sizes-1 btn-block" data-toggle="modal" data-target="#exampleModalCenter10">
                                    <i
                                        class="fa fa-plus"></i> New Items
                                </button>
                                {/* Modal */}

                                <div className="dialog-box-wrapper form-dialog half-width">
                                    <div className="modal fade text-left" id="exampleModalCenter10" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenter10Title" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header ">
                                                    <h4>Add</h4>
                                                    <button type="button" className="btn text-danger" data-dismiss="modal">X</button>
                                                </div>
                                                <form className="form-sample" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post("/itemsapi", data).then(res => {
                                                        if (res.data !== 'err') {
                                                            window.$('#exampleModalCenter10').modal('hide');
                                                            // $('#expenses_header_id').val(res.data[0]);
                                                            getitemsContent();
                                                        }
                                                    })
                                                }}>
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="category_id" className="form-label">کەتیگۆری</label>
                                                            <select className="form-select" name="category_id" required>
                                                                {categoryContent.map((obj, x) => (
                                                                    <option key={obj.id} value={obj.id}>{obj.name_krd}|{obj.name_ar}|{obj.name_en}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="name_krd">Kurdish Name</label>
                                                            <input id="title_krd" className="form-control" type="text" name="title_krd" required placeholder="ناوی کاڵا" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="title_ar">Arabic Name</label>
                                                            <input id="title_ar" className="form-control" type="text" name="title_ar" required placeholder="غرض" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="title_en">English  Name</label>
                                                            <input id="title_en" className="form-control" type="text" name="title_en" required placeholder="Item name" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="description_krd">Kurdish Description</label>
                                                            <input id="description_krd" className="form-control" type="text" name="description_krd" required placeholder="ڕوونکردنەوە" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="description_ar">Arabic Description</label>
                                                            <input id="description_ar" className="form-control" type="text" name="description_ar" required placeholder="وصف" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="description_en">English  Description</label>
                                                            <input id="description_en" className="form-control" type="text" name="description_en" required placeholder="Description" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="price">Price</label>
                                                            <input id="price" className="form-control" type="number" name="price" required placeholder="Description" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="currency" className="form-label">دراوو</label>
                                                            <select className="form-select" name="currency" required >

                                                                <option value='$'>$</option>
                                                                <option value='د.ع'>د.ع</option>

                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="priority">Priority</label>
                                                            <input id="priority" className="form-control" type="number" name="priority" required placeholder="Priority" />
                                                        </div>

                                                        <div className="mb-3">
                                                            <label> Image Icon</label>
                                                            <input type="hidden" name="cover" required id="attachment" />
                                                            <div className="form-control text-center" onClick={() => upload()}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                            </div>
                                                            <br />
                                                            <div align="center" id="upattachment"></div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-success">Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="table-wrapper">
                                <table class="table">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Kurish Name</th>
                                            <th scope="col">Arabic Name</th>
                                            <th scope="col">English Name</th>
                                            <th scope="col">Kurish Description</th>
                                            <th scope="col">Arabic Description</th>
                                            <th scope="col">English Description</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Priority</th>
                                            <th scope="col">Showing</th>
                                            <th scope="col">Cover</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {itemsContent && itemsContent.map((item) => (
                                            item.id ? (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.category}</td>
                                                    <td>{item.title_krd}</td>
                                                    <td>{item.title_ar}</td>
                                                    <td>{item.title_en}</td>
                                                    <td>{item.description_krd}</td>
                                                    <td>{item.description_ar}</td>
                                                    <td>{item.description_en}</td>
                                                    <td>{item.price} {item.currency}</td>
                                                    <td>{item.priority}</td>
                                                    <td>{item.showing}</td>
                                                    <td>{item.cover ? (
                                                        <a href={`../../../${item.cover}`} target="_blank">
                                                            <img className="wd-30 ht-30 rounded-circle" width={'50px'} height={'50px'} src={`../../../${item.cover}`} alt={item.cover} />
                                                        </a>
                                                    ) : (
                                                        <span className="badge bg-danger">Null</span>
                                                    )}
                                                    </td>
                                                    <td>
                                                        <span type="button" className="badge bg-warning mx-2" data-toggle="modal" data-target={`#update${item.id}`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                        </span>
                                                        <div className="dialog-box-wrapper form-dialog half-width">
                                                            <div className="modal fade text-left" id={`update${item.id}`} tabIndex={-1} role="dialog" aria-labelledby={`update${item.id}`} aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header ">
                                                                            <h4>Add</h4>
                                                                            <button type="button" className="btn text-danger" data-dismiss="modal">X</button>
                                                                        </div>
                                                                        <form className="form-sample" onSubmit={(e) => {
                                                                            e.preventDefault();
                                                                            const formData = new FormData(e.target);
                                                                            const data = Object.fromEntries(formData);
                                                                            axios.put(`/itemsapi/${item.id}`, data).then(res => {
                                                                                if (res.data !== 'err') {
                                                                                    window.$(`#update${item.id}`).modal('hide');
                                                                                    // $('#expenses_header_id').val(res.data[0]);
                                                                                    getitemsContent();
                                                                                }
                                                                            })
                                                                        }}>
                                                                            <div className="modal-body">
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="category_id" className="form-label">کەتیگۆری</label>
                                                                                    <select className="form-select" defaultValue={item.category_id} name="category_id" required>
                                                                                        {categoryContent.map((obj, x) => (
                                                                                            <option key={obj.id} value={obj.id}>{obj.name_krd}|{obj.name_ar}|{obj.name_en}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <label htmlFor="name_krd">Kurdish Name</label>
                                                                                    <input id="title_krd" className="form-control" defaultValue={item.title_krd} type="text" name="title_krd" required placeholder="ناوی کاڵا" />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="title_ar">Arabic Name</label>
                                                                                    <input id="title_ar" className="form-control" defaultValue={item.title_ar} type="text" name="title_ar" required placeholder="غرض" />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="title_en">English  Name</label>
                                                                                    <input id="title_en" className="form-control" type="text" defaultValue={item.title_en} name="title_en" required placeholder="Item name" />
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <label htmlFor="description_krd">Kurdish Description</label>
                                                                                    <input id="description_krd" className="form-control" defaultValue={item.description_krd} type="text" name="description_krd" required placeholder="ڕوونکردنەوە" />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="description_ar">Arabic Description</label>
                                                                                    <input id="description_ar" className="form-control" type="text" defaultValue={item.description_ar} name="description_ar" required placeholder="وصف" />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="description_en">English  Description</label>
                                                                                    <input id="description_en" className="form-control" type="text" defaultValue={item.description_en} name="description_en" required placeholder="Description" />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="price">Price</label>
                                                                                    <input id="price" className="form-control" type="number" defaultValue={item.price} name="price" required placeholder="Description" />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="currency" className="form-label">دراوو</label>
                                                                                    <select className="form-select" defaultValue={item.currency} name="currency" required >

                                                                                        <option value='$'>$</option>
                                                                                        <option value='د.ع'>د.ع</option>

                                                                                    </select>
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <label htmlFor="priority">Priority</label>
                                                                                    <input id="priority" className="form-control" defaultValue={item.priority} type="number" name="priority" required placeholder="Priority" />
                                                                                </div>

                                                                                <div className="mb-3">
                                                                                    <label htmlFor="showing" className="form-label">Showing</label>
                                                                                    <select className="form-select" defaultValue={item.showing} name="showing" required >

                                                                                        <option value='1'>Show</option>
                                                                                        <option value='0'>Hide</option>

                                                                                    </select>
                                                                                </div>

                                                                                <div className="mb-3">
                                                                                    <label> Image Icon</label>
                                                                                    <input type="hidden" name="cover" defaultValue={item.cover} required id={`uploadattachment${item.id}`} />
                                                                                    <div className="form-control text-center" onClick={() => Upupload(item.id)}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                                                    </div>
                                                                                    <br />
                                                                                    <div align="center" id={`upattachment${item.id}`}></div>
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                                                                    <button type="submit" className="btn btn-success">Save</button>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span type="button" className="badge bg-danger" onClick={() => {
                                                            Swal.fire({
                                                                title: 'ئاگاداری',
                                                                text: "ئایا دڵنیایت لە سڕینەوەی ئەم کاڵایە ؟",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'بەڵێ',
                                                                cancelButtonText: 'نەخێر'
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/itemsapi/${item.id}`).then(() => {
                                                                        toast.success("کاڵا سڕایەوە");
                                                                        getitemsContent();
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                        </span>
                                                    </td>

                                                </tr >
                                            ) : null
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </section>
                <FooterAdmin />
            </div>
        </>
    );
}
export default Items