const Header = ({ lang, switchLang, data }) => {
    return (
        <div className="header">
            <div className="fixed-header">
                <div className="container-fluid">
                    <div className="header-logo">
                        {data.logo ? <img src={`../../${data.logo}`} style={{ height: '37px' }} alt="logo" /> : <h4>{data[`name_${lang}`]}</h4>}
                    </div>
                    <div className="header-links">
                        <ul className="nav">
                            <li className="nav-item" >
                                <span type='button' className={`nav-link ${lang==='krd'? 'active':null}`} onClick={(e) => switchLang(e, 'krd')}>کوردی</span>
                            </li >
                            <li className="nav-item" >
                                <span type='button' className={`nav-link ${lang==='ar'? 'active':null}`} onClick={(e) => switchLang(e, 'ar')}>عربي</span>
                            </li >
                            <li className="nav-item" >
                                <span type='button' className={`nav-link ${lang==='en'? 'active':null}`} onClick={(e) => switchLang(e, 'en')}>English</span>
                            </li >
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;