
import $ from 'jquery'
import { Link } from "react-router-dom";
import router from '../route';

const SlideNav = () => { 
    var CloseSlidervar = false;
    const CloseSlider = () => { 
        $('#mySidenav').toggle(); 
        if (!CloseSlidervar) {
            $('.overlay-black').css({ 'visibility': 'hidden' })
            CloseSlidervar = true;
        }
        else {
            //$('.overlay-black').css({ 'visibility': 'visible' })
            CloseSlidervar = false;
        }
    }

    return (
        <div class="sidenav-content" style={{ display: "none" }} id="mySidenav">
            <div id="mySidenav" className="sidenav to-left-toggle">
                <div id="web-name">
                    <h2><span><i className="far fa-star" /></span> <br /> <span>Res</span>taurant</h2> 
                </div>{/* end web-name */}
                <div id="main-menu">
                    <div className="sidenav-closebtn">
                        <button className="btn btn-default" id="sidenav-close" onClick={CloseSlider}>X</button>
                    </div>{/* end sidenav-closebtn */}
                    <div className="list-group">
                        <Link to="/category" className="list-group-item"><span><i className="fa fa-users sidebar-icon" /></span>Category</Link>
                        <Link to="/items" className="list-group-item"><span><i className="fa fa-cutlery sidebar-icon" /></span>Menu</Link>
                        <Link to="/message" className="list-group-item"><span><i className="fa fa-cutlery sidebar-icon" /></span>Message</Link>
                    </div>{/* end list-group */}
                </div>{/* end main-menu */}
            </div>
        </div>
    );
}
export default SlideNav